<template>
  <div class="view-selectPay">
    <van-popup v-model="show" position="bottom" round @close="close">
      <div class="title">
        <span class="close" @click="show = false">取消</span>
        线上支付
      </div>
      <div class="content">
        <ul class="list">
          <li
            class="item"
            v-for="(item, index) in payInfo.rowList"
            :key="index"
          >
            <div class="label">{{ item.name }}</div>
            <div class="price">{{ item.price }}元</div>
          </li>
        </ul>
        <div class="total">{{ payInfo.total }}<span>元</span></div>
        <van-radio-group v-model="payMethod" class="payMethod">
          <van-cell-group>
            <van-cell clickable @click="payMethod = '4'">
              <div class="label">
                <img src="@/assets/imgs/account/WXPay.png" alt="" />
                微信支付
              </div>
              <template #right-icon>
                <van-radio name="4" icon-size="16px" />
              </template>
            </van-cell>
            <van-cell clickable @click="payMethod = '1'">
              <div class="label">
                <img src="@/assets/imgs/account/AliPay.png" alt="" />
                支付宝支付
              </div>
              <template #right-icon>
                <van-radio name="1" icon-size="16px" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <div class="button">
        <van-button type="info" @click="goPay">
          确认支付{{ payInfo.total }}元
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    props: {
      payInfo: {
        default: {
          rowList: [],
          total: 0,
        },
      },
    },
    data() {
      return {
        show: true,
        payMethod: '4', // 0-微信二维码 1-支付宝二维码 4-微信支付
      }
    },
    created() {},
    methods: {
      goPay() {
        this.$emit('goPay', {
          total: this.payInfo.total,
          payMethod: this.payMethod,
        })
      },
      close() {
        this.$emit('close')
      },
    },
  }
</script>

<style lang="less" scoped>
  .view-selectPay {
    /deep/ .van-popup {
      .title {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        border-bottom: 1px solid #f5f5f5;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #111a34;
        line-height: 25px;
        background: #f4f5f6;
        position: relative;
        .close {
          font-size: 14px;
          color: #666666;
          position: absolute;
          left: 14px;
          top: 18px;
        }
      }
      .content {
        .list {
          padding: 25px 64px;
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
            line-height: 14px;
            margin-bottom: 10px;
          }
        }
        .total {
          font-size: 36px;
          font-family: DIN, DIN-Medium;
          font-weight: 500;
          text-align: center;
          color: #111a34;
          line-height: 36px;
          padding-bottom: 25px;
          border-bottom: 1px solid #f5f5f5;
          span {
            font-size: 16px;
          }
        }
        .payMethod {
          .van-cell {
            padding: 13px 46px;
            .van-cell__value {
              display: flex;
              align-items: center;
              .label {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
                line-height: 14px;
                img {
                  width: 15px;
                  margin-right: 9px;
                }
              }
            }
          }
          .van-cell:after {
            display: none;
          }
        }
      }
      .button {
        box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
        background: #fff;
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px 20px;
        .van-button {
          flex: 1;
          margin-right: 10px;
          border-radius: 4px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          line-height: 25px;
        }
        .van-button--disabled {
          opacity: 0.2;
        }
        & :last-child {
          margin: 0;
        }
      }
    }
  }
</style>
