import { get, post } from './axios'

export default {
  // 获取财务信息
  getAccountInfo(param) {
    return get('/user/account/getAccountInfo', param)
  },
  // 获取个人财务信息
  getPersonAccountInfo(param) {
    return get('/user/account/getPersonAccountInfo', param)
  },
  // 购买套餐支付申请
  depositApply(param) {
    return post('/user/account/depositApply', param)
  },
  // 个人购买套餐支付申请
  personDepositApply(param) {
    return post('/user/account/personDepositApply', param)
  },
  // 支付状态
  getTradeStatus(param) {
    return get('/user/account/getTradeStatus', param)
  },
}
