<template>
  <div class="view-account">
    <div class="account-info" v-if="accountInfo">
      <div class="item">
        <div class="value">{{ accountInfo.leftUmber }}</div>
        <div class="label">可用份数</div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="value">{{ accountInfo.usedUmber }}</div>
        <div class="label">已用份数</div>
      </div>
    </div>
    <div class="title">购买套餐</div>
    <ul class="setMealList">
      <li
        class="item"
        v-for="(item, index) in identity ? setMealList : setMealList"
        :key="index"
        :class="{ active: index + 1 == active }"
        @click="active = index + 1"
      >
        <div class="tag">{{ item.label }}</div>
        <div class="priceForm">
          <div class="price"><span>¥</span>{{ item.price }}</div>
        </div>
        <!-- <div class="detail">
          <div>份数：{{ item.num }}</div>
          <div>{{ item.unitPrice }}元/份</div>
        </div> -->
      </li>
    </ul>
    <div class="title">自定义购买</div>
    <div class="customPurchase">
      <div class="left">
        <van-field
          v-model="fileNum"
          center
          label="文件份数"
          placeholder=""
          maxlength="5"
          type="number"
          :formatter="formatter"
          @focus="active = null"
        >
          <template #extra>
            <div class="unitPrice">
              <span>份</span>
              {{ getCurPriceStrategy(fileNum).unitPrice }}元/份
            </div>
          </template>
        </van-field>
      </div>
      <div class="right">
        总计
        <span>¥{{ totalMoney }}</span>
      </div>
    </div>
    <template v-if="identity">
      <div class="title">对公转账</div>
      <div class="organizationForm">
        <div class="organization-info">
          请您使用<span>企业对公账户打款至“{{ systemInfo.company }}账户”</span
          >，汇款用途填写<span>“{{ systemInfo.company }}账户充值”</span
          >，账户信息如下所示。
        </div>
        <div class="organization-table">
          <div class="item">
            <div class="label">公司名称</div>
            <div class="value">{{ systemInfo.companyName }}</div>
          </div>
          <div class="item">
            <div class="label">公司开户行</div>
            <div class="value">{{ systemInfo.CompanyAccountBank }}</div>
          </div>
          <div class="item">
            <div class="label">账号</div>
            <div class="value">{{ systemInfo.CompanyAccount }}</div>
          </div>
          <div class="item">
            <div class="label">地址</div>
            <div class="value">{{ systemInfo.CompanyAddress }}</div>
          </div>
          <div class="item">
            <div class="label">电话</div>
            <div class="value">{{ systemInfo.CompanyTel }}</div>
          </div>
          <div class="item">
            <div class="label">纳税人识别号</div>
            <div class="value">
              {{ systemInfo.TaxpayerIdentificationNumber }}
            </div>
          </div>
        </div>
        <div class="organization-tag">
          汇款后，无需提交汇款信息，我们会在收到您的汇款后自动为您充值到账
        </div>
        <div class="organization-tips">
          <div>温馨提示：</div>
          <div>1. 受银行处理时间影响，采用线下汇款方式实际到账会有延时</div>
          <div>
            2.各银行到账时间一般为：中国银行转账为1天，其他银行转账为1-2天（具体以银行实际到账时间为准）
          </div>
          <div>3. 如有疑问，请致电客服 010-86392456</div>
        </div>
      </div>
    </template>
    <div class="view-button">
      <van-button
        type="info"
        @click="goBuy"
        :disabled="active || fileNum ? false : true"
      >
        立即购买
      </van-button>
    </div>
    <selectPay
      v-if="payShow"
      :payInfo="payInfo"
      @goPay="goPay"
      @close="close"
    ></selectPay>
    <van-dialog
      v-model="successShow"
      class="view-dialog"
      confirmButtonText="知道了"
      @confirm="successConfirm"
    >
      <img class="dialog-icon" src="@/assets/imgs/account/success.png" alt="" />
      <div class="dialog-title">支付成功</div>
      <div class="dialog-tag">请您查看账户可用份数</div>
    </van-dialog>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import selectPay from '@/components/selectPay/selectPay.vue'
  import accountApi from '@/api/account'

  export default {
    components: {
      selectPay,
    },
    data() {
      // 组织身份、定价策略
      // x < 100 份 8
      // 100 <= x < 500 4.5
      // 500 <= x < 1000 2.9
      // 1000 <= x < 2000 2.45
      // x >= 2000 2
      this.priceStrategies = {
        A: {
          min: 0,
          max: 10,
          unitPrice: '6.00',
        },
        B: {
          min: 10,
          max: 100,
          unitPrice: '5.00',
        },
        C: {
          min: 100,
          max: 1000,
          unitPrice: '4.00',
        },
        D: {
          min: 1000,
          max: 5000,
          unitPrice: '3.00',
        },
        Z: {
          min: 5000,
          unitPrice: '2.00',
        },
      }
      return {
        successShow: false, // 支付成功弹窗
        accountInfo: null, // 财务信息
        fileNum: null, // 自定义购买数量
        active: null, // 选中的套餐
        payShow: false, // 支付选项
        setMealList: [
          // {
          //   label: 'A 套餐',
          //   price: 50,
          //   unitPrice: '5.00',
          //   num: 10,
          // },
          // {
          //   label: 'B 套餐',
          //   price: 400,
          //   unitPrice: '4.00',
          //   num: 100,
          // },
          // {
          //   label: 'C 套餐',
          //   price: 3000,
          //   unitPrice: '3.00',
          //   num: 1000,
          // },
          // {
          //   label: 'D 套餐',
          //   price: 10000,
          //   unitPrice: '2.00',
          //   num: 5000,
          // },
          {
            label: '单份体验套餐',
            price: 5.9,
            unitPrice: '5.90',
            num: 1,
          },
          {
            label: '双份优惠套餐',
            price: 9.99,
            unitPrice: '4.99',
            num: 2,
          },
          {
            label: '4份体验套餐',
            price: 19.99,
            unitPrice: '4.99',
            num: 4,
          },
          {
            label: '6份体验套餐',
            price: 29.99,
            unitPrice: '4.99',
            num: 6,
          },
          {
            label: '10份体验套餐',
            price: 49.99,
            unitPrice: '4.99',
            num: 10,
          },
          {
            label: '20份体验套餐',
            price: 99.99,
            unitPrice: '4.99',
            num: 20,
          },
          {
            label: '100份体验套餐',
            price: 400,
            unitPrice: '4.00',
            num: 100,
          },
          {
            label: '1000份体验套餐',
            price: 3000,
            unitPrice: '3.00',
            num: 1000,
          },
          {
            label: '5000份体验套餐',
            price: 10000,
            unitPrice: '2.00',
            num: 5600,
          },
        ],
        count: null,
        payInfo: {
          rowList: [],
          total: 0,
        },
        meal: {},
        isSource: 1, // 0.其他平台和自定义,1.公众号购买套餐
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
        userInfo: state => state.user.userInfo,
      }),
      identity() {
        if (this.userInfo.comId) {
          return 1
        }
        return 0
      },

      // 自定义价格计算规则
      // x < 100 份 8
      // 100 <= x < 500 4.5
      // 500 <= x < 1000 2.9
      // 1000 <= x < 2000 2.45
      // x >= 2000 2
      totalMoney() {
        return this.fileNum
          ? (
              this.getCurPriceStrategy(this.fileNum).unitPrice * this.fileNum
            ).toFixed(2)
          : 0
      },
    },
    created() {
      if (this.identity) {
        this.getAccountInfo()
      } else {
        this.getPersonAccountInfo()
      }
    },
    methods: {
      // 根据count来计算选择哪个定价策略
      getCurPriceStrategy(count) {
        this.count = count
        // 企业身份
        const { A, B, C, D } = this.priceStrategies
        if (count >= A.min && count < A.max) {
          return A
        }
        if (count >= B.min && count < B.max) {
          return B
        }
        if (count >= C.min && count < C.max) {
          return C
        }
        if (count >= C.max) {
          return D
        }
      },
      formatter(value) {
        // 过滤输入的数字
        return value.replace(/\D/g, '')
      },
      goBuy() {
        if (this.active) {
          this.isSource = 1
          if (this.identity) {
            this.payInfo = {
              rowList: [
                {
                  name: this.setMealList[this.active - 1].label,
                  price: this.setMealList[this.active - 1].price,
                },
              ],
              total: this.setMealList[this.active - 1].price,
            }
            this.meal = {
              name: this.setMealList[this.active - 1].label,
              price: this.setMealList[this.active - 1].price,
              total: this.setMealList[this.active - 1].num,
            }
          } else {
            this.payInfo = {
              rowList: [
                {
                  name: this.setMealList[this.active - 1].label,
                  price: this.setMealList[this.active - 1].price,
                },
              ],
              total: this.setMealList[this.active - 1].price,
            }
            this.meal = {
              name: this.setMealList[this.active - 1].label,
              price: this.setMealList[this.active - 1].price,
              total: this.setMealList[this.active - 1].num,
            }
          }
        } else {
          // 自定义购买
          this.isSource = 0
          this.payInfo = {
            rowList: [
              {
                name: '自定义购买',
                price: this.totalMoney,
              },
            ],
            total: this.totalMoney,
          }
        }
        // this.meal = {
        //   name: this.setMealList[this.active - 1].label,
        //   price: this.setMealList[this.active - 1].price,
        //   total: this.setMealList[this.active - 1].num,
        // }
        this.payShow = true
      },
      getAccountInfo() {
        accountApi
          .getAccountInfo({
            identity: this.identity,
          })
          .then(res => {
            this.accountInfo = res
          })
      },
      getPersonAccountInfo() {
        accountApi
          .getPersonAccountInfo({
            identity: this.identity,
          })
          .then(res => {
            this.accountInfo = res
          })
      },
      close() {
        this.payShow = false
      },
      goPay(payInfo) {
        this.payShow = false
        // payInfo.payMethod  4 微信支付  1 支付宝支付
        const obj = {
          ...payInfo,
          copiesNum: this.active ? payInfo.total : this.fileNum,
        }

        window.wx.miniProgram.getEnv(res => {
          if (res.miniprogram) {
            this.$toast('请在微信公众号内打开')
          } else {
            if (this.identity) {
              this.depositApply(obj)
            } else {
              this.personDepositApply(obj)
            }
          }
        })
      },
      depositApply(payInfo) {
        let depositAmount = Math.round(parseFloat(this.meal.price) * 100)
        let uitPrice = this.getCurPriceStrategy(this.fileNum).unitPrice
        let taoPrice = uitPrice * this.count
        let price = this.isSource === 0 ? taoPrice * 100 : depositAmount
        accountApi
          .depositApply({
            depositAmount: price,
            // this.meal.price * 100,
            identity: this.identity,
            payMethod: payInfo.payMethod,
            copiesNum: this.isSource === 0 ? this.count : this.meal.total,
            source: this.isSource,
          })
          .then(res => {
            this.payNext(payInfo, res)
          })
      },
      personDepositApply(payInfo) {
        let depositAmount = Math.round(parseFloat(this.meal.price) * 100)
        let uitPrice = this.getCurPriceStrategy(this.fileNum).unitPrice
        let taoPrice = uitPrice * this.count
        let price = this.isSource === 0 ? taoPrice * 100 : depositAmount
        accountApi
          .personDepositApply({
            depositAmount: price,
            identity: this.identity,
            payMethod: payInfo.payMethod,
            copiesNum: this.isSource === 0 ? this.count : this.meal.total,
            source: this.isSource,
          })
          .then(res => {
            this.payNext(payInfo, res)
          })
      },
      successConfirm() {
        if (this.$route.query.path && this.$route.query.key) {
          this.$router.push({
            path: this.$route.query.path,
            query: {
              key: this.$route.query.key,
            },
          })
          return
        }
        this.$router.push({
          path: '/my',
        })
      },
      payNext(payInfo, data) {
        const that = this
        if (payInfo.payMethod === '4') {
          // 微信支付
          if (!window.sessionStorage.openId) {
            this.$toast('请在微信公众号内打开')
            return
          }
          const payToken = JSON.parse(data.payToken)
          window.wx.config({
            debug: false,
            appId: payToken.appId,
            timestamp: payToken.timestamp,
            nonceStr: payToken.nonceStr,
            signature: payToken.signature,
            jsApiList: ['checkJsApi'],
          })
          window.wx.ready(() => {
            window.wx.chooseWXPay({
              timestamp: payToken.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: payToken.nonceStr, // 支付签名随机串，不长于 32 位
              package: payToken.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: payToken.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: payToken.paySign, // 支付签名
              success(res) {
                // 支付成功后的回调函数
                that.successShow = true
              },
            })
          })
        }
        if (payInfo.payMethod === '1') {
          // 支付宝支付
          this.$router.push({
            path: '/payGuide',
            query: {
              tradeId: data.tradeId,
              payToken: data.payToken,
              path: this.$route.query.path,
              key: this.$route.query.key,
            },
          })
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .view-account {
    padding: 16px;
    padding-bottom: calc(@safe-area + 66px);
    background-color: #fff;
    .account-info {
      width: 343px;
      height: 81px;
      border-radius: 8px;
      background: url('../../assets/imgs/account/accountInfoBg.png') no-repeat
        center;
      background-size: 100%;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .line {
        width: 1px;
        height: 31px;
        opacity: 0.52;
        border: 1px solid #ffffff;
      }
      .item {
        flex: 1;
        .value {
          font-size: 27px;
          font-family: DINPro, DINPro-Medium;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
          line-height: 34px;
          margin-bottom: 5px;
        }
        .label {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
          line-height: 17px;
        }
      }
    }
    .title {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: bold;
      text-align: left;
      color: #111a34;
      line-height: 22px;
      margin-bottom: 16px;
    }
    .setMealList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 8px;
      .item {
        box-sizing: border-box;
        position: relative;
        width: 164px;
        overflow: hidden;
        background: linear-gradient(
          138deg,
          #f5f8ff -5%,
          rgba(235, 241, 255, 0.5) 98%
        );
        border: 1px solid #e9f1ff;
        border-radius: 6px;
        margin-bottom: 16px;
        .tag {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;
          height: 22px;
          background: linear-gradient(132deg, #bacbe7, #a8bad8 96%);
          border-radius: 0px 0px 16px 0px;
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
          line-height: 17px;
          padding: 0 15px 0 12px;
        }
        .priceForm {
          height: 79px;
          display: flex;
          align-items: center;
          justify-content: center;
          .price {
            font-size: 26px;
            font-family: DINPro, DINPro-Medium;
            font-weight: 500;
            text-align: center;
            color: #111a34;
            margin-top: 18px;
            span {
              font-size: 12px;
            }
          }
        }
        .detail {
          padding: 0 10px;
          height: 36px;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #677283;
          line-height: 17px;
        }
      }
      .active {
        border: 1px solid #1676ff;
        .tag {
          background: linear-gradient(317deg, #4692ff 15%, #5da0ff 120%);
        }
        .priceForm {
          .price {
            color: #1676ff;
          }
        }
      }
    }
    .customPurchase {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      .left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 224px;
        /deep/ .van-field {
          padding: 0;
          .van-field__label {
            width: auto;
            margin-right: 7px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #111a34;
            line-height: 20px;
          }
          .van-field__value {
            border-bottom: 1px solid #f1f1f1 !important;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: bold;
            text-align: right;
            color: #111a34;
            line-height: 22px;
            input {
              text-align: center;
            }
          }
          .unitPrice {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #111a34;
            line-height: 20px;
            span {
              margin: 0 23px 0 7px;
            }
          }
        }
      }
      .right {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #111a34;
        line-height: 20px;
        span {
          color: #1676ff;
          margin-left: 7px;
        }
      }
    }
    .organizationForm {
      .organization-info {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #404c56;
        line-height: 24px;
        margin-bottom: 16px;
        span {
          color: #1676ff;
        }
      }
      .organization-table {
        border: 1px solid #f0f0f0;
        border-radius: 4px 4px 0px 0px;
        margin-bottom: 16px;
        .item {
          box-sizing: border-box;
          padding: 16px;
          display: flex;
          align-items: center;
          .label {
            width: 100px;
            font-size: 13px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #6d7790;
            line-height: 18px;
          }
          .value {
            flex: 1;
            font-size: 13px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #111a34;
            line-height: 18px;
          }
        }
        .item:nth-child(odd) {
          background-color: #f4f7ff;
        }
        .item:nth-child(even) {
          background-color: #ffffff;
        }
      }
      .organization-tag {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #fe943d;
        line-height: 24px;
        margin-bottom: 16px;
      }
      .organization-tips {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #6d7790;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }
  }
  .view-dialog {
    /deep/ .van-dialog__content {
      text-align: center;
      padding: 24px;
      .dialog-icon {
        width: 60px;
        margin-bottom: 18px;
      }
      .dialog-title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #202124;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .dialog-tag {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #677283;
        line-height: 24px;
      }
    }
  }
</style>
